<template>
  <div class="onboarding__container">
    <h1 class="smalltitle" style="padding-bottom: 100px; padding-top: 20px">
      {{ current_user.username }}, we are setting up Carl-bot in
      {{ current_guild.name }}
    </h1>
    <div class="underlined__title">
      <h4 class="smalltitle">GENERAL</h4>
    </div>
    <div>
      <b-card class="onboarding__card">
        <b-form-group>
          <h4 class="smalltitle">Custom Prefixes</h4>
          <p>You can have up to 15 prefixes</p>
          <vue-tags-input
            v-model="prefix_temp"
            :tags="prefixes"
            @tags-changed="(newTags) => (prefixes = newTags)"
            :allow-edit-tags="true"
            :max-tags="15"
            :maxlength="50"
            style="background-color: #4f5b63; max-width: none; width: 100%"
            placeholder="Add word"
          />
          <div class="prefix__container">
            <div class="prefix__example">
              <div class="prefix__example__prefix">{{ prefixString }}</div>
              ping
            </div>
            <div class="prefix__example">
              <div class="prefix__example__prefix">{{ prefixString }}</div>
              ban @Carl#0001 rude boy
            </div>
            <div class="prefix__example">
              <div class="prefix__example__prefix">{{ prefixString }}</div>
              rr make
            </div>
          </div>
        </b-form-group>
      </b-card>
    </div>
    <div class="onboarding__footer">
      <button class="saucy-btn" v-on:click="doUpdate">Next</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import { createTags, VueTagsInput } from "@johmun/vue-tags-input";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {
    Callout,
    cSwitch,
    VueTagsInput,
  },
  data: function () {
    return {
      prefixes: createTags(["!", "?"]),
      prefix_temp: "",
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Nickname changed",
    },
    showInfoMsg: {
      type: VueNotifications.types.info,
      title: "No changes",
      message: "You already have that prefix.",
    },
    showWarnMsg: {
      type: VueNotifications.types.success,
      title: "Wow, man",
      message: "That's the kind of warning",
    },
    showSuccessPrefix: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Prefix added",
    },
    showSuccessPrefixRemoval: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Prefix removed",
    },
    showErrorMsgPrefix: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You need to enter a prefix!",
    },
    showErrorMsgNick: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You need to enter a nickname!",
    },
    showErrorMsgDuplicate: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You already have that prefix!",
    },
    showErrorMsgTooManyPrefixes: {
      type: VueNotifications.types.error,
      title: "Uh-oh",
      message: "You already have the maximum allowed number of prefixes!",
    },
  },
  computed: {
    prefixStrings() {
      return this.prefixes.map((p) => p.text);
    },
    prefixString() {
      const prefixes = this.prefixStrings;
      const prefix = prefixes[0];
      if (!prefix) {
        return "@Carl-bot#1536 ";
      }
      return prefix;
    },
    current_user() {
      return this.$store.state.discord_user || {};
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        (x) => x.id == this.$route.params.guild_id
      );
    },
  },
  methods: {
    async doUpdate() {
      const guild_id = this.$route.params.guild_id;
      await this.axios.put(`/api/v1/servers/${guild_id}/meta`, {
        prefixes: this.prefixes.map((p) => p.text),
        bot_channel: 123,
      });
      this.$router.push(`/onboarding/${guild_id}/welcome`);
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$store.commit("onboardingStage", 0);
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/meta")
      .then((r) => {
        this.prefixes = createTags(r.data.prefixes || []);
      });
  },
  created: function () {
    this.$Progress.start();
    this.$store.commit("onboardingStage", 0);
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/meta")
      .then((r) => {
        this.prefixes = createTags(r.data.prefixes || []);
        this.$Progress.finish();
      })
      .catch((e) => {
        this.$Progress.finish();
      });
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>
<style scoped>
.prefix__container {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
}
.prefix__example {
  background-color: #292a33;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: 500;
  border-radius: 3px;
  color: #aaa;
}

.prefix__example__prefix {
  color: white;
}
</style>
<style>
.vue-tags-input {
  background: #505b64;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

div.vue-tags-input {
  background: #505b64 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  border: 1px solid #222823;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #22222a;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #292a33;
  color: #eee;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #292a33;
  color: #292a33;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}

.onboarding__container {
  display: flex;
  align-items: center;
  background-color: #22222a;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.underlined__title {
  border-bottom: 4px solid #49d6df;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-self: center;
}
</style>